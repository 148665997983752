const empty = {
  upload: {
    ext: "Upload",
    status: null,
    headline: "File Upload",
    nFiles: null,
    pointer: null,
  },
  traverse: {
    ext: "Traverse",
    status: null,
    headline: "Traversing Directory",
    nFiles: null,
    pointer: null,
  },
  zip: {
    ext: "ZIP",
    status: null,
    headline: "Recursive Unzip",
    nFiles: null,
    pointer: null,
  },
  xlsm: {
    ext: "XLSM",
    status: null,
    headline: "XLSM to XLSX",
    nFiles: null,
    pointer: null,
  },
  tab: {
    ext: "TAB",
    status: null,
    headline: "TAB to CSV",
    nFiles: null,
    pointer: null,
  },
  dat: {
    ext: "DAT",
    status: null,
    headline: "DAT to CSV",
    nFiles: null,
    pointer: null,
  },
  tsv: {
    ext: "TSV",
    status: null,
    headline: "TSV to CSV",
    nFiles: null,
    pointer: null,
  },
  ssv: {
    ext: "SSV",
    status: null,
    headline: "SSV to CSV",
    nFiles: null,
    pointer: null,
  },
  xls: {
    ext: "XLS",
    status: null,
    headline: "XLS to CSV",
    nFiles: null,
    pointer: null,
  },
  xlsx: {
    ext: "XLSX",
    status: null,
    headline: "XLSX to CSV",
    nFiles: null,
    pointer: null,
  },
  txt: {
    ext: "TXT",
    status: null,
    headline: "TXT to CSV",
    nFiles: null,
    pointer: null,
  },
  socan: {
    ext: "SOCAN",
    status: null,
    headline: "SOCAN to CSV",
    nFiles: null,
    pointer: null,
  },
  prsfw: {
    ext: "PRSFW",
    status: null,
    headline: "PRS FW to CSV",
    nFiles: null,
    pointer: null,
  },
  welk: {
    ext: "WELK",
    status: null,
    headline: "WELK to CSV",
    nFiles: null,
    pointer: null,
  },
  /*pdf: {
    ext: "pdf",
    status: null,
    headline: "PDF to CSV",
    nFiles: null,
    pointer: null,
  },*/
  csv: {
    ext: "CSV",
    status: null,
    headline: "Encode to Utf8",
    nFiles: null,
    pointer: null,
  },
  str: {
    ext: "CSV",
    status: null,
    headline: "Correct string formatting",
    nFiles: null,
    pointer: null,
  },

};

const initialState = {
  converters: empty,
  showConverters: false,
  scanning: false
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  let converters;

  // -------------------------------------------------------
  switch(action.type) {
    // -------------------------------------------------------
    case 'INIT_CONVERTERS':
      return { ...state, converters: JSON.parse(JSON.stringify(empty)) }

    // -------------------------------------------------------
    case 'CONVERTERS_COMPLETE_STEP':
      converters = { ...state.converters };
      converters[action.ext].status = "done";
      return { ...state, converters }

    // -------------------------------------------------------
    case 'SET_CONVERSION_STATS':
      converters = { ...state.converters };      
      Object.keys(converters).forEach(
        key => {
          converters[key].nFiles = action.counts[key];
          converters[key].pointer = null;
        }
      )
      return { ...state, converters }

    
    // -------------------------------------------------------
    case 'SET_SCANNING':
      return { ...state, scanning: action.scanning }

    // -------------------------------------------------------
    case 'SET_CONVERSION_PROGRESS':
      converters = { ...state.converters };
      converters[action.ext].pointer = action.pointer;
      converters[action.ext].status = 'progress';

      return { ...state, converters }
    // -------------------------------------------------------
    case 'SET_CHECKIN_PROGRESS':
      converters = { ...state.converters };
      converters[action.ext].pointer = action.pointer;
      converters[action.ext].nFiles = 100;
      converters[action.ext].status = "progress";
      return { ...state, converters }

    // -------------------------------------------------------
    case 'SET_ZIP_STATS':
      converters = { ...state.converters };      
      converters.zip.pointer = 0;
      converters.zip.nFiles = action.nZips;
      return { ...state, converters }

    // -------------------------------------------------------
    case 'SHOW_HIDE_CONVERTERS':
      return { ...state, showConverters: action.showConverters }

    // -------------------------------------------------------
    default:
      return state;
  }
}

export default reducer;